<template>
	<div class="uu-login">
		<div class="uu-login-head">
			<div class="logo"><img src="@/assets/images/logo-278-61.png" alt=""></div>
		</div>
		<div class="uu-login-body">
			<div class="uu-login-wrap">
				<div class="uu-login-main">
					<div class="uu-login-tabs">
						<div class="tab-pane" :class="loginForm.type==1?'active':''" @click="handleChangeTabs(1)">{{ $t('login.studentTitle') }}</div>
						<div class="tab-pane" :class="loginForm.type==2?'active':''" @click="handleChangeTabs(2)">{{ $t('login.teacherTitle') }}</div>
					</div>
					<div class="uu-login-box">
						<el-form :model="loginForm" ref="loginForm" :rules="loginRules">
							<!-- <el-form-item>
								<h1 class="title">密码登录</h1>
							</el-form-item> -->
							<el-form-item prop="username">
								<el-input v-model="loginForm.username" clearable :placeholder="loginForm.type | nameFilter($t('login.placeholder.usernameJson'))" @keyup.enter.native="handleSubmit">
									<i slot="prefix" class="el-input__icon el-icon-user"></i>
								</el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input v-model="loginForm.password" type="password" clearable :placeholder="$t('login.placeholder.password')" @keyup.enter.native="handleSubmit">
									<i slot="prefix" class="el-input__icon el-icon-lock"></i>
								</el-input>
							</el-form-item>
							<!-- <el-form-item>
								<a href="#">{{ $t('login.forget') }}</a>
								<a href="#">{{ $t('login.register') }}</a>
							</el-form-item> -->
							<el-form-item>
								<el-button type="login" @click="handleSubmit">{{ $t('login.logIn') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
					<!-- <div class="uu-login-bottom" v-if="loginForm.type == 1">
						<div class="title">第三方登录</div>
						<div class="third-party">
							<div class="item" @click="handleThirdParty">酷特云平台</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
		<div class="uu-login-footer">
			<div class="msg1">{{ $t('login.footer.title') }}</div>
			<div class="msg2">{{ $t('login.footer.copyright') }}</div>
			<!-- <div class="msg3">{{ $t('login.footer.ICP') }}</div> -->
		</div>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	import { getAppid } from '@/api/user'
	export default {
		filters: {
			nameFilter(type, map) {
				return map[type]
			}
		},
		data() {
			var validateName = (rule, value, callback) => {
				if(value === '') {
					let json = this.$t('login.validate.usernameJson')
					callback(new Error(json[this.loginForm.type]))
				} else {
					callback()
				}
			}
			return {
				loginForm: {
					type: 1,
					username: '',
					password: '',
				},
				loginRules: {
					username: [{
						required: true,
						validator: validateName,
						trigger: 'blur'
					}, ],
					password: [{
							required: true,
							message: this.$t('login.validate.password'),
							trigger: 'blur'
						},
						{
							min: 6,
							message: this.$t('login.validate.passwordMin'),
							trigger: 'blur'
						}
					],
				},
				uuAppid: ''
			}
		},
		created () {
			this.getAppid()	
		},
		methods: {
			handleChangeTabs(type){
				this.loginForm.type = type;
				this.$refs.loginForm.resetFields()
			},
			handleSubmit() {
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						let params = {
							type: this.loginForm.type,
							username: this.loginForm.username,
							password: md5(this.loginForm.password).toString(),
							app_key: this.$store.getters.appKey,
							uuid: this.$store.getters.uuid,
						}
						this.$store.dispatch('user/login', params)
							.then(() => {
								this.$notify({
									title: this.$t('login.messageTips'),
									type: "success",
									message: this.$t('login.successMsg'),
									duration: 3000,
								});
								if (this.$route.query.jump_url) {
									window.location.href = this.$route.query.jump_url
								} else {
									window.location.href = this.$workBasePath
								}
							})
							.catch(() => {
							})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			getAppid() {
				getAppid({app_key: this.$store.getters.appKey}).then(response => {
					this.uuAppid = response.data.appid
				})
			},
			handleThirdParty() {
				let url = this.$loginBasePath+'/auth-redirect'
				if (this.$route.query.jump_url) {
					url += '?jump_url='+this.$route.query.jump_url
				}
				const base = encodeURIComponent(url)
				window.location.href = process.env.VUE_APP_UUTALENT_BASE+'/auth-login?id='+this.uuAppid+'&s=appSecret&b=' + base
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-login {
		height: 100%;

		.uu-login-head {
			width: 1280px;
			height: 80px;
			padding: 16px 20px;
			box-sizing: border-box;
			margin: 0 auto;

			.logo {
				float: left;
				height: 48px;

				img {
					height: 100%;
				}
			}
		}

		.uu-login-body {
			min-width: 1280px;
			height: calc(100% - 260px);
			min-height: 480px;
			background-image: url('~@/assets/images/login_bg.jpg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			.uu-login-wrap {
				width: 1280px;
				height: 100%;
				display: flex;
				margin: 0 auto;
				align-items: center;
				justify-content: flex-end;
				padding: 0 40px;
				box-sizing: border-box;
				.uu-login-main {
					width: 360px;
					min-height: 340px;
					border-radius: 4px;
					background-color: rgba(255, 255, 255, .7);
				}
				.uu-login-tabs {
					display: flex;
					margin-bottom: 20px;
					.tab-pane {
						flex: 1;
						text-align: center;
						font-size: 16px;
						height: 48px;
						line-height: 48px;
						cursor: pointer;
						border-bottom: 2px solid #FFFFFF;
					}
					.tab-pane.active {
						color: $--login-primary;
						border-bottom-color: $--login-primary;
					}
				}
				.uu-login-box {
					padding: 10px 30px;
					.title {
						color: #333333;
						font-size: 18px;
					}

					.el-button--login {
						color: #FFFFFF;
						width: 100%;
						background-color: $--login-primary;
						border-color: $--login-primary;
						&:focus,&:hover {
							border-color: $--login-primary-hover;
							background-color: $--login-primary-hover;
						}
					}
				}
				.uu-login-bottom {
					text-align: center;
					padding-bottom: 20px;
					.title {
						color: #999;
						margin-bottom: 20px;
						&:before,&:after {
							content: "";
							display: inline-block;
							vertical-align: middle;
							width: 40px;
							height: 1px;
							background-color: #999;
							margin: 0 20px;
						}
					}
					.third-party {
						.item {
							color: #777;
							cursor: pointer;
						}
					}
				}
			}
		}

		.uu-login-footer {
			width: 1280px;
			margin: 0 auto;
			padding: 55px 0 20px;
			line-height: 20px;

			.msg1 {
				padding: 12px;
				border-top: 1px solid #DDD;
				border-bottom: 1px solid #DDD;
			}

			.msg2 {
				padding: 12px 0 7px 12px;
				color: #AAA;
			}

			.msg3 {
				padding-left: 12px;
				color: #666;
			}
		}
	}
</style>
